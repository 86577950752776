/* Standard SiteVision CSS classes */
.sv-no-list-style {
  list-style: none;
}

.sv-noborder {
  border-style: none;
}

.sv-noborder-nospacing {
  border-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.sv-noborder-vamiddle {
  border-style: none;
  vertical-align: middle;
}

.sv-linkicon {
  padding-left: 2px;
  border-style: none;
}

.sv-corner-all {
  border-radius: 4px;
}

.sv-clear-left {
  clear: left;
}

.sv-clear-right {
  clear: right;
}

/* These two has to be separated, it's an IE issue it will
   ignore rules if there is an unrecognized selector. In this case '>'... */
.sv-horizontal .sv-horizontal-column,
.sv-float-left,
.sv-horizontal-list li {
  float: left;
}

.sv-horizontal > div.ui-state-highlight {
  float: left;
}

.sv-float-right {
  float: right;
}

.sv-border-box {
  box-sizing: border-box;
}

.sv-clear-both {
  clear: both;
}

.sv-wrap-words {
  word-wrap: break-word;
}

.sv-use-margins h1,
.sv-use-margins h2,
.sv-use-margins h3,
.sv-use-margins h4,
.sv-use-margins h5,
.sv-use-margins h6 {
  display: block;
}

.sv-fontsize-reset {
  font-size: 100% !important;
}

/* HTML5 reset */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
main,
nav,
section {
  display: block;
}

.sv-responsive .sv-buddyicon-portlet img,
.sv-responsive .sv-randomimage-portlet img,
.sv-responsive .sv-archive-portlet .sv-channel-image,
.sv-responsive .sv-linklist-portlet .sv-channel-image,
.sv-responsive .sv-blog-portlet .sv-channel-image {
  width: 100%;
  height: auto;
}

/* Custom view classes */
.sv-relative {
  position: relative;
}

.sv-toolitem {
  float: left;
}
/**
 * To clear floats add class 'sv-clearfix' to the
 * parent of the floated elements
 */
.sv-horizontal:after,
.sv-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.sv-buddy-icon {
  border: none;
}

.sv-buddy-icon,
.sv-corner-all {
  /* top left */
  border-radius: 3px;
}

/* Default table css */
.sv-linklist-portlet table,
.sv-blog-portlet table,
.sv-archive-portlet table,
.sv-text-portlet table {
  background-color: transparent;
  border-collapse: collapse;
}

table.sv-border-collapse-separate {
  border-collapse: separate;
}

.sv-linklist-portlet th,
.sv-blog-portlet th,
.sv-archive-portlet th,
.sv-text-portlet th {
  text-align: left;
}

.sv-linklist-portlet table p,
.sv-blog-portlet table p,
.sv-archive-portlet table p,
.sv-text-portlet table p {
  margin: 0;
}

.sv-linklist-portlet table col[class*='col-'],
.sv-blog-portlet table col[class*='col-'],
.sv-archive-portlet table col[class*='col-'],
.sv-text-portlet table col[class*='col-'] {
  position: static;
  float: none;
  display: table-column;
}

.sv-linklist-portlet table td[class*='col-'],
.sv-blog-portlet table td[class*='col-'],
.sv-archive-portlet table td[class*='col-'],
.sv-text-portlet table td[class*='col-'],
.sv-archive-portlet table th[class*='col-'],
.sv-text-portlet table th[class*='col-'] {
  position: static;
  float: none;
  display: table-cell;
}

/* Content list styles */
.sv-edit-mode .sv-contentlist-portlet .pagecontent {
  outline: none;
}

@media print {
  .sv-template-portlet,
  #sv-editor-menu {
    display: none;
    padding: 0;
    margin: 0;
  }

  .sv-template-layout {
    border: none;
    background: white;
    width: 100%;
    min-width: 0;
    padding: 0;
    margin: 0;
    float: none !important;
  }

  body {
    background: white;
    font-size: 12pt;
  }

  * {
    font-family: 'Times New Roman', serif !important;
  }
}
