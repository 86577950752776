/* fix for images with HTML5-doctypes adds extra padding underneath See SV-9978 for details */
.sv-image-portlet,
.sv-image-portlet a {
  line-height: 0;
}

.sv-responsive .sv-image-portlet img {
  width: 100%;
  height: auto;
}
