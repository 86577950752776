.sv-toast__container {
  position: fixed;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
}

.sv-bottom-left .env-toast {
  bottom: -200px;
}

.sv-bottom-left .env-toast.env-toast--visible {
  bottom: 0;
}

.sv-bottom-left .env-toast--hide {
  transform: translateX(-1000px);
}

.sv-toast__container.sv-bottom-left {
  bottom: var(--env-spacing-medium);
  left: var(--env-spacing-medium);
  margin-right: var(--env-spacing-medium);
}
